import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import useCluster from "./hooks/useCluster";

import Loading from "../../components/Loading/Loading";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import Slider from "../../components/Slider/Slider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import ClusterOverview from "./ClusterOverview/ClusterOverview";

import QuestionCard, { groupQuestions } from "./QuestionCard/QuestionCard";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faLightbulb } from "@fortawesome/free-solid-svg-icons";

import {
  formatCurrency,
  formatFloat,
  formatPercent,
} from "../../utils/tableUtils";

import youtube from "../../assets/img/youtube.png";

import "./Cluster.css";

import dragon from "../../assets/img/dragon.webp";

function Cluster() {
  const location = useLocation();
  const history = location.state?.history || [];
  const { clusterId, profileId } = useParams();
  const { cluster, loading, error } = useCluster(clusterId, profileId);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  // set element with class App to overflow hidden when lightbox is open
  useEffect(() => {
    if (lightboxOpen) {
      document.querySelector(".App").style.overflow = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector(".App").style.overflow = "auto";
      document.querySelector("html").style.overflow = "auto";
    }
  }, [lightboxOpen]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              {error?.message || "Something went wrong."}
            </p>
          </div>
        </section>
      </div>
    );
  }

  if (cluster == null || Object.keys(cluster).length === 0) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">
              No cluster data available for this profile.
            </p>
          </div>
        </section>
      </div>
    );
  }

  const groupedQuestions = groupQuestions(cluster.cluster_data?.popular_questions, 2);

  return (
    <>
      <Helmet>
        <title>{cluster?.cluster_data?.idea?.title} | Genti</title>
      </Helmet>

      <div
        className="cluster page-base space-y-4 md:space-y-8"
      >
        <section className="page-section">
          <div className="section-header flex flex-col md:flex-row justify-between items-center gap-4">
            <h1>{cluster?.cluster_data?.idea?.title}</h1>

            <button 
              onClick={() => setLightboxOpen(true)}
              className="text-base flex gap-2 items-center rounded border border-[#0561B7] border-opacity-50 hover:border-opacity-100 p-2 h-full whitespace-nowrap">
              View full plan
              <FontAwesomeIcon icon={faLightbulb} />
            </button>
          </div>

          <div className="cluster-overview">
          <img
              src={cluster?.cluster_data?.cluster_img || dragon}
              alt=""
              className="cluster-img"
              onClick={() => setLightboxOpen(true)}
            />

            <div className="concept">
              <div className="">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-bold text-2xl mb-2">Concept</h3>
                    <p>
                      {cluster?.cluster_data?.idea?.description}
                    </p>
                  </div>

                  <div className="bg-deep-space px-4 py-3 rounded-b border-t border-dotted border-[#0561B7]">
                    <h4 className="font-bold text-xl">Action Plan</h4>
                    <ul className="space-y-2 p-4 px-8">
                      { cluster?.cluster_data?.idea?.plan?.map((planItem, planIndex) => (
                          <li key={planIndex} className="flex items-center gap-x-2">
                            <FontAwesomeIcon icon={faCheck} />
                            {planItem.title}
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

            </div>
            <ClusterOverview cluster={cluster} />
          </div>
        </section>

        <section className="page-section">
          <h2 className="section-header">Most Popular Questions</h2>

          { cluster.cluster_data?.popular_questions ? <Slider>
            {groupedQuestions.map((questionPair, slideIndex) => (
              <div className="slide flex flex-col gap-4 p-4" key={slideIndex}>
                {questionPair.map((question, index) => (
                  <div key={index} className="flex-1">
                    <QuestionCard
                      question={question}
                      index={slideIndex * 2 + index}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Slider>
          : <p className="p-4 text-center">No popular questions available for this cluster.</p>
          }
        </section>

        <section className="page-section">
          <h2 className="section-header">Intent</h2>

          <div className="cluster-summar p-6 space-y-6">
            <ol className="grid md:grid-cols-2 gap-x-4 md:gap-x-24 gap-y-4 md:gap-y-6 w-full">
              {cluster.cluster_data?.search_motivations?.map(
                (reason, index) => (
                  <li key={index}>
                    <h3 className="font-bold text-lg">{reason.title}</h3>
                    <p className="">{reason.description}</p>
                  </li>
                )
              )}
            </ol>
          </div>
        </section>

        <section className="page-section">
          <div>
            <h2 className="section-header">Keywords</h2>
            <ChartSlider>
              <SearchVolMonth
                data={cluster.cluster_data?.total_search_volume_by_month}
              />
              <SearchVolBubble
                data={
                  cluster.cluster_data?.visualizations?.search_volume_by_type
                }
              />
              <PaidSERP
                data={cluster.cluster_data?.visualizations?.paid_serp}
              />
              <ClusterSERPFeatures
                data={cluster.cluster_data?.visualizations?.serp_features}
              />
            </ChartSlider>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="info-boxes grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Share</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.visualizations?.cluster_share[1].y, 2) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }/kws</div>
                </div>
              </div>
              </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                  <h2 className="font-bold text-center">
                    Total Volume (mo/yr)
                  </h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_search_volume, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_search_volume * 12, 0) }</div>
                  </div>
                </div>
              </div>


              <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
                <div className="space-y-4">
                  <h2 className="font-bold text-center">Difficulty (avg/high)</h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.most_difficult_diff?.max_kwd_value, 0) }</div>
                  </div>
                </div>
              </div>

              <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
                <div className="space-y-4">
                  <h2 className="font-bold text-center">CPC (avg/high)</h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.avg_cpc) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.most_expensive_cpc?.max_cpc_value, 2) }</div>
                  </div>
                </div>
              </div>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="overflow-x-auto">
            <ClusterTable
              data={cluster.cluster_data?.visualizations?.keywords}
              profileId={profileId}
              history={history}
            />
          </div>
        </section>
      </div>

      { lightboxOpen && <div className="fixed inset-0 bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-80 backdrop-blur-lg dark:backdrop-blur-lg mx-auto overflow-scroll z-[50]">
        <button
          className="fixed top-4 right-4 rounded-full h-10 w-10 text-lg hover:bg-white hover:bg-opacity-20"
          onClick={() => setLightboxOpen(false)}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>

        <div className="w-[90%] py-12 mx-auto space-y-12 min-h-full">
          <section className="page-section">
            <h2 className="section-header">
              {cluster?.cluster_data?.idea?.title}
            </h2>

            <div
              className="p-4 grid gap-4"
              style={{
                gridTemplateColumns: "200px 1fr",
                gridTemplateRows: "auto auto",
              }}
            >
              <img
                src={cluster.cluster_data.cluster_img || dragon}
                alt=""
                className="h-[200px] w-[200px] rounded-md object-cover col-span-1 row-span-2"
              />
              <div className="col-span-1">
                <h3 className="font-bold text-lg">Concept</h3>
                <p>
                  {cluster?.cluster_data?.idea?.description}
                </p>
              </div>

              <div>
                <p className="">
                  { cluster?.cluster_data?.idea?.details }
                </p>
              </div>
            </div>
          </section>

          { cluster?.cluster_data?.idea?.plan?.map((planItem, planIndex) => (
            <div className="page-section" key={planIndex}>
              <h3 className="font-bold text-3xl section-header">{planItem.title}</h3>
              {planItem.steps && (
                <ul className="p-4 text-2xl space-y-3">
                  {planItem.steps.map((bullet, bulletIndex) => (
                    <li key={bulletIndex} className="flex items-baseline gap-x-4">
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      <span>{bullet}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}

          { cluster?.cluster_data?.idea?.soundtrack && <section className="page-section">
            <a className="flex items-center gap-4 p-4" href={ cluster?.cluster_data?.idea?.soundtrack[2] } target="_blank" rel="noreferrer">
            <div className="flex-1">
              <h2 className="text-xl font-bold">{ cluster?.cluster_data?.idea?.soundtrack[0] }</h2>
              <p className="text-lg">{ cluster?.cluster_data?.idea?.soundtrack[1] }</p>
            </div>

                <img className="h-16" src={youtube} alt={ cluster?.cluster_data?.idea?.soundtrack[0] } />
              </a>
          </section>}
        </div>
      </div>}
    </>
  );
}

export default Cluster;

