import { useEffect, useMemo, useRef } from 'react'
import { TextureLoader, SRGBColorSpace, MeshBasicMaterial } from 'three';
import * as THREE from 'three'
import { useFrame, useThree, useLoader } from '@react-three/fiber'
import { useGLTF, Float, useFBO } from '@react-three/drei'

import bgDark from '../assets/img/bg-dark-2.png'
import bgLight from '../assets/img/bg-light-2.png'

import sunrise from '../assets/models/sunrise.glb'

import { useTheme } from '../utils/ThemeManager';

import vertex from './shaders/vertex.js'
import fragment from './shaders/fragment.js'

export default function Sunrise(props) {
  const { theme } = useTheme()
  const { nodes } = useGLTF(sunrise)
  const mesh = useRef()

  const { scene } = useThree()

  const mainRenderTarget = useFBO()
  const backRenderTarget = useFBO()

  const darkTexture = useLoader(TextureLoader, bgDark)
  const lightTexture = useLoader(TextureLoader, bgLight)
  // darkTexture.encoding = lightTexture.encoding = SRGBColorSpace;
  darkTexture.colorSpace = lightTexture.colorSpace = SRGBColorSpace;

  const params = {
    light: new THREE.Vector3(-1.0, 1.0, 1.0),
    diffuseness: 0.1,
    shininess: 40.0,
    fresnelPower: 5.0,
    iorR: 1.15,
    iorY: 1.16,
    iorG: 1.18,
    iorC: 1.22,
    iorB: 1.22,
    iorP: 1.22,
    saturation: 1.08,
    chromaticAberration: 0.6,
    refraction: 0.2,
  }

  const uniforms = useMemo(() => ({
    uTexture: { value: null },
    uIorR: { value: 1.0 },
    uIorY: { value: 1.0 },
    uIorG: { value: 1.0 },
    uIorC: { value: 1.0 },
    uIorB: { value: 1.0 },
    uIorP: { value: 1.0 },
    uRefractPower: { value: 0.2 },
    uChromaticAberration: { value: 1.0 },
    uSaturation: { value: 0.0 },
    uShininess: { value: 40.0 },
    uDiffuseness: { value: 0.2 },
    uFresnelPower: { value: 8.0 },
    uLight: { value: new THREE.Vector3(-1.0, 1.0, 1.0) },
    winResolution: {
      value: new THREE.Vector2(
        window.innerWidth, 
        window.innerHeight
      ).multiplyScalar(Math.min(window.devicePixelRatio, 2))
    }
  }), [])

  useEffect(() => {
    scene.background = theme === 'dark' ? darkTexture : lightTexture
  }, [theme])

  useFrame((state, delta) => {
    const { gl, scene, camera } = state;
    mesh.current.visible = false;

    mesh.current.material.uniforms.uDiffuseness.value = params.diffuseness;
    mesh.current.material.uniforms.uShininess.value = params.shininess;
    mesh.current.material.uniforms.uLight.value = params.light;
    mesh.current.material.uniforms.uFresnelPower.value = params.fresnelPower;

    mesh.current.material.uniforms.uIorR.value = params.iorR;
    mesh.current.material.uniforms.uIorY.value = params.iorY;
    mesh.current.material.uniforms.uIorG.value = params.iorG;
    mesh.current.material.uniforms.uIorC.value = params.iorC;
    mesh.current.material.uniforms.uIorB.value = params.iorB;
    mesh.current.material.uniforms.uIorP.value = params.iorP;

    mesh.current.material.uniforms.uSaturation.value = params.saturation;
    mesh.current.material.uniforms.uChromaticAberration.value = params.chromaticAberration;
    mesh.current.material.uniforms.uRefractPower.value = params.refraction;

    gl.setRenderTarget(backRenderTarget)
    gl.render(scene, camera)

    mesh.current.material.uniforms.uTexture.value = backRenderTarget.texture
    mesh.current.material.side = THREE.BackSide

    mesh.current.visible = true

    gl.setRenderTarget(mainRenderTarget)
    gl.render(scene, camera)

    mesh.current.material.uniforms.uTexture.value = mainRenderTarget.texture
    mesh.current.material.side = THREE.FrontSide

    gl.setRenderTarget(null)

    if (mesh.current) {
      mesh.current.rotation.y += delta * 0.25
    }
  })

  return <Float floatIntensity={0.025} rotationIntensity={0} speed={1}>
    <mesh ref={mesh} renderOrder={100} geometry={nodes.SUN.geometry} {...props}>
      {/* <meshBasicMaterial attach="material" color="red" /> */}
      <shaderMaterial 
        attach="material" 
        uniforms={uniforms}
        vertexShader={vertex} 
        fragmentShader={fragment} 
      />
    </mesh>
  </Float>
}
