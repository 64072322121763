import { useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faKey, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { formatFloat } from "../../utils/tableUtils";
import { generateGradientColors, hexToRgb } from "../../utils/chartUtils";
import { useTheme } from "../../utils/ThemeManager";

import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import ChartSlider from "../../components/ChartSlider/ChartSlider";
import UniverseCharts from "../KeywordUniverse/Visualizations/UniverseCharts/UniverseCharts";
import SearchVolBubble from "./Visualizations/SearchVolBubble";

import useProject from "../../hooks/useProject";
import useClusters from "./hooks/useClusters";

import "./Clusters.css";

function Clusters() {
  const { theme } = useTheme();
  const { clusterId } = useParams();
  const { project, loading: loadingProject} = useProject(clusterId);
  const { clusterFeed, clusterName, clusterType, loading, error } = useClusters(clusterId);
  const [sortConfig, setSortConfig] = useState({
    key: "total_search_volume",
    direction: "descending",
  });
  const [colors, setColors] = useState({
    dark: [],
    light: [],
  });

  const location = useLocation();
  const history = location.state?.history || [];
  const category = location.state?.category || "All";
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(category);
  const inputRef = useRef(null);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "ascending" ? (
        <FontAwesomeIcon className="ml-2" icon={faCaretUp} />
      ) : (
        <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
      );
    }
    return null;
  };

  const queries = searchQuery
    .split(",")
    .map((q) => q.trim().toLowerCase())
    .filter((query) => query.length > 0);

  const filteredClusterFeed = clusterFeed
    .filter((item) => {
      if (!searchQuery.trim()) return true; // If searchQuery is empty, return all items
      return queries.some((query) => item.title.toLowerCase().includes(query));
    })
    .filter(
      (item) =>
        selectedCategory === "All" || item.primary_category === selectedCategory
    );

  const sortedClusterFeed = [...filteredClusterFeed].sort((a, b) => {
    let aValue = a[sortConfig.key] || 0;
    let bValue = b[sortConfig.key] || 0;
    return sortConfig.direction === "ascending"
      ? aValue - bValue
      : bValue - aValue;
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [clusterFeed]);

  useEffect(() => {
    setColors({
      dark: generateGradientColors("#FF3C6D", "#FF7347", queries.length),
      light: generateGradientColors("#020021", "#0561B7", queries.length),
    });
  }, [theme, searchQuery]);

  if (loading || loadingProject) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <ErrorDisplay error={error} />
      </div>
    );
  }

  if (clusterFeed.length === 0) {
    return (
      <div className="page-base cluster-feed space-y-4">
        <section className="page-section">
          <h1 className="section-header">Cluster Feed</h1>
          <div className="p-8 flex flex-col gap-4 justify-center items-center">
            <h2 className="text-2xl text-semibold text-center">
              No cluster feed available.
            </h2>
            <Link
              to={location.state?.from || "/discover"}
              className="btn w-full max-w-[400px]"
            >
              {location.state?.from ? "Back" : "Back to Companies"}
            </Link>
          </div>
        </section>
      </div>
    );
  }

  return <>
    <Helmet>
      <title>{`Genti - ${clusterName}`}</title>
    </Helmet>

    <div className="page-base cluster-feed space-y-4">
      { clusterType === "Brand Project" 
        && project !== null
        && <ProjectInfoHeader project={project} linkToProjectPage={true} />
      }

      <section className="page-section">
        <h1 className="section-header">Cluster Feed</h1>

        <div className="grid grid-cols-10 grid-rows-3 md:grid-rows-1 gap-2 lg:gap-y-1 p-2 lg:px-4 lg:pt-7">
          <input
            type="text"
            value={searchQuery}
            ref={inputRef}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for clusters (comma-separated values allowed)"
            className="input col-span-full md:col-span-6 px-4 py-2 border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7] "
          />

          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="input col-span-full md:col-span-2 px-4 py-2 border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7]"
          >
            <option value="All">All Categories</option>
            { clusterType && clusterType === "Brand Project"
            ? <>
                <option value="General Branded">General Branded</option>
                <option value="Payment">Payment</option>
                <option value="Usage">Usage</option>
                <option value="Side Effects">Side Effects</option>
                <option value="Evaluation">Evaluation</option>
                <option value="Assistance">Assistance</option>
            </>
            : <>
                <option value="General Condition">General Condition</option>
                <option value="Symptoms">Symptoms</option>
                <option value="Diagnosis">Diagnosis</option>
                <option value="Treatment">Treatment</option>
                <option value="Preventative">Preventative</option>
                <option value="Clinical Trials">Clinical Trials</option>
                <option value="Support">Support</option>
            </>

            }
          </select>

          <div className="col-span-full md:col-span-2 flex gap-1 md:gap-2 border border-[#0561B7] border-opacity-50 dark:bg-[#0561B7] dark:bg-opacity-10 rounded">
            <button
              onClick={() => requestSort("total_search_volume")}
              className="w-full lg:min-w-[70px]"
              title="Sort by Total Search Volume"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <span className="sr-only">Total Search Volume</span>
              {getSortArrow("total_search_volume")}
            </button>
            <button
              onClick={() => requestSort("total_keyword_count")}
              className="w-full lg:min-w-[70px]"
              title="Sort by Total Keyword Count"
            >
              <FontAwesomeIcon icon={faKey} />
              <span className="sr-only">Total Keyword Count</span>
              {getSortArrow("total_keyword_count")}
            </button>
          </div>
        </div>

        <div className="hidden md:block">
          <ChartSlider>
            <SearchVolBubble 
              data={filteredClusterFeed} 
              model_name={clusterName} 
              history={history} 
              clusterId={clusterId} 
              searchQuery={searchQuery} 
            />
            {/* need condition analysis data: { clusterType && clusterType !== "Brand Project" && <UniverseCharts data={project?.keyword_universe} condition={project?.condition_analysis_name} category_type="Condition" />} */}
            { clusterType && clusterType === "Brand Project" && <UniverseCharts data={project?.keyword_universe} project_name={project?.name} category_type="Branded" /> }

          </ChartSlider>
        </div>

        <div>
          <ul className="cluster-list">
            {sortedClusterFeed.map((item, index) => {
              const itemQueryIndex = queries.findIndex((query) =>
                item.title.toLowerCase().includes(query)
              );
              const itemColor =
                itemQueryIndex !== -1
                  ? colors[theme][itemQueryIndex % colors[theme].length]
                  : theme === 'dark' ? "#FF3C6D" : "#0561B7";
              return (
                <li
                  key={index}
                  className="relative page-section cluster-item"
                  style={{
                    "--hover-color": itemColor,
                    '--hover-color-rgb': hexToRgb(itemColor),
                  }}
                >
                  <div style={{ 
                      backgroundColor: `rgba(${hexToRgb(itemColor)}, 0.6)`,
                      border: `1px solid ${itemColor}` 
                    }} 
                    className={`indicator absolute -bottom-4 -left-4 h-8 w-8 bg-[${itemColor}] rounded-full`} />
                  <ExternalLink
                    title={item.title}
                    className="cluster-link"
                    url={`/cluster/${clusterId}/${item.uuid}`}
                  >
                    <h2 className="section-header">
                      <span>{item.title || "No summary"}</span>
                    </h2>
                    <p className="p-4 pb-0">
                      <span>{item.summary || "No summary"}</span>
                    </p>
                    <div
                      title="Total Search Volume"
                      className="flex justify-between items-end gap-4 px-4 py-2 text-sm"
                    >
                      <div className="text-xs">
                        {item.primary_category || ""}
                      </div>
                      <div className="flex gap-4">
                        <div className="flex gap-2 items-center">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                          <span>
                            {formatFloat(item.total_search_volume, 0)}
                          </span>
                        </div>
                        <div
                          title="Total Keyword Count"
                          className="flex gap-2 items-center"
                        >
                          <FontAwesomeIcon icon={faKey} />
                          <span>
                            {formatFloat(item.total_keyword_count, 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ExternalLink>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </div>
  </>;
}

export default Clusters;
