import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { auth } from '../firebase';

export default create(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),

      // Function to clear the entire user object
      clearUser: async () => {
        await auth.signOut();
        set({ user: null });
      },

      // persist
      name: 'session',
      storage: createJSONStorage(() => sessionStorage),
    }),
    {
      name: 'session',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);