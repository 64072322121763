import React, { useEffect, useRef, useState } from 'react';
import { useSprings, animated, useSpring } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import clamp from 'lodash.clamp';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import './Slider.css';

function Slider({ children, className }) {
  const childrenArray = React.Children.toArray(children);

  // Keep an index ref so we can increment/decrement without causing extra re-renders
  const indexRef = useRef(0);

  // Track the actively visible slide index (for dot highlighting, arrow styling, etc.)
  const [activeIndex, setActiveIndex] = useState(0);

  // Track arrow key states (pressed vs. not) for styling
  const [keyMap, setKeyMap] = useState({
    ArrowRight: false,
    ArrowLeft: false,
  });

  // Measure the slider’s width for horizontal transitions
  const [sliderRef, { width }] = useMeasure();

  // Measure the active child’s height if you want variable height
  const [childRef, { height: childHeight }] = useMeasure();

  // A separate spring to animate container’s height so each slide can have its own height
  const [{ containerHeight }, containerApi] = useSpring(() => ({
    containerHeight: 0,
    config: { tension: 250, friction: 30 },
  }));

  const controlSectionOffset = 64;

  // One spring per slide
  const [springs, api] = useSprings(
    childrenArray.length,
    (i) => ({
      x: i * width,
      display: i === 0 ? 'block' : 'none',
      immediate: true,
    }),
    [width]
  );

  // Whenever our active slide’s height changes, animate the container to match it
  useEffect(() => {
    containerApi.start({ containerHeight: childHeight + controlSectionOffset });
  }, [childHeight, containerApi]);

  // Listen for left/right arrows
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        moveIndex(1); // go to next
        setKeyMap({ ArrowRight: true, ArrowLeft: false });
      } else if (e.key === 'ArrowLeft') {
        moveIndex(-1); // go to prev
        setKeyMap({ ArrowRight: false, ArrowLeft: true });
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        setKeyMap({ ArrowRight: false, ArrowLeft: false });
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [width]);

  // Move index by delta (±1)
  function moveIndex(delta) {
    indexRef.current = clamp(
      indexRef.current + delta,
      0,
      childrenArray.length - 1
    );
    updateSlides(indexRef.current);
  }

  // Jump to specific slide
  function jumpTo(idx) {
    indexRef.current = idx;
    updateSlides(idx);
  }

  // Animate slides in/out
  function updateSlides(newIndex) {
    setActiveIndex(newIndex);
    api.start((i) => {
      if (i < newIndex - 1 || i > newIndex + 1) {
        // Hide slides that aren’t near the active
        return { display: 'none' };
      }
      // Put relevant slides on screen
      const x = (i - newIndex) * width;
      return { x, display: 'block', immediate: false };
    });
  }

  return (
    <animated.div
      // Animate container height if needed for variable-height slides
      style={{ height: containerHeight }}
      className={`new-slider-container relative w-full overflow-hidden ${className || ''}`}
    >
      {/* The "slides" container (ref for measuring width) */}
      <div ref={sliderRef} className="swipe-wrapper relative">
        {springs.map(({ x, display }, i) => {
          // Only measure the active slide's height
          const measureRef = i === activeIndex ? childRef : null;
  
          const isActive = i === activeIndex;

          return (
            <animated.div
              key={i}
              className="swipe-page absolute top-0 left-0 w-full h-auto"
              style={{
                display,
                transform: x.to((val) => `translate3d(${val}px,0,0)`),
              }}
              ref={measureRef}
              aria-hidden={isActive ? 'false' : 'true'}
            >
              <div className="swipe-page-content w-full h-full">
                {childrenArray[i]}
              </div>
            </animated.div>
          );
        })}
      </div>

      {/* 
        NAVIGATION at bottom with high z-index 
        so it appears above the chart or slides 
      */}
      <div
        className="navigation-container 
                   absolute bottom-0 left-0 w-full 
                   z-40 p-4
                   flex justify-between items-center
                   bg-[#f5f5f5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10"
      >
        {/* Dots */}
        <div className="navigation-dots flex items-center gap-2">
          {childrenArray.map((_, i) => (
            <button
              key={i}
              className={`dot ${activeIndex === i ? 'active' : ''}`}
              onClick={() => jumpTo(i)}
              aria-label={`Go to slide ${i + 1}`}
            />
          ))}
        </div>

        {/* Arrows */}
        <div className="navigation-arrows flex gap-4">
          <button
            aria-label="Previous slide"
            className={`arrow left nav-button ${keyMap.ArrowLeft ? 'active' : ''}`}
            onClick={() => moveIndex(-1)}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>

          <button
            aria-label="Next slide"
            className={`arrow right nav-button ${keyMap.ArrowRight ? 'active' : ''}`}
            onClick={() => moveIndex(1)}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </div>
      </div>
    </animated.div>
  );
}

export default Slider;
