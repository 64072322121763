import Summary from "../../../../components/Summary/Summary";
import spinner from "../../../../assets/img/spinner.svg";
import { Link } from "react-router-dom";

import image from "../../image1.webp";

import "./MedicationOverview.css";

export const formatDate = (dateString) => {
  if (dateString == null || dateString === "") return "Unavailable";

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function MedicationOverview({ project, brand, loading }) {
  if (loading) {
    return (
      <div className="medication-overview-container">
        <div className="overview-bar loading">
          <img src={spinner} alt="Loading..." className="h-8 w-8" />
        </div>

        <div className="info-container loading">
          <img src={spinner} alt="Loading..." className="h-8 w-8" />
        </div>
      </div>
    );
  }

  return (
    <div className="medication-overview-container">
      <div className="overview-bar">
        <div className="info-container">
          <div className="overview-item">
            <h3>Company:</h3>
            <Link
              to={`/company/${project?.parent_company}`}
              state={{
                from: `/project/${project?.uuid}`,
                history: [
                  {
                    name: "Discover",
                    path: "/discover",
                  },
                ],
              }}
            >
              {project?.parent_company_name || "Unavailable"}
            </Link>
          </div>
          {/* Company */}

          <div className="overview-item">
            <h3>Condition:</h3>
            <span>{project?.condition_analysis_name || "Unavailable"}</span>
          </div>
          {/* Condition */}

          <div className="overview-item">
            <h3>Category:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.approval_category_main ? (
                  <p className="capitalize">
                    {project.acnu_features[0].v1.approval_category_main.toLowerCase()}
                  </p>
                ) : (
                  <p>N/A</p>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <p className="capitalize">
                  {project.acnu_features.v1?.approval_category_main?.toLowerCase() ||
                    "Unavailable"}
                </p>
              ) : (
                <p>N/A</p>
              ))}
          </div>
          {/* Category */}

          <div className="overview-item">
            <h3>Approval Date:</h3>
            <span>{formatDate(project?.most_recent_approval)}</span>
          </div>
          {/* Approval Date */}
        </div>

        <div className="info-container">
          <div className="overview-item">
            <h3>Dosage Form:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.dosage_form ? (
                  <ul className="capitalize">
                    {project.acnu_features[0].v1.dosage_form
                      ?.split(", ")
                      .map((form, i) => (
                        <li key={i}>{form.toLowerCase()}</li>
                      ))}
                  </ul>
                ) : (
                  <p>N/A</p>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <ul className="capitalize">
                  {project.acnu_features.v1.dosage_form
                    ?.split(", ")
                    .map((form, i) => (
                      <li key={i}>{form.toLowerCase()}</li>
                    ))}
                </ul>
              ) : (
                <p>N/A</p>
              ))}
          </div>
          {/* Dosage Form */}

          <div className="overview-item">
            <h3>Dosage Route:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.dosage_route ? (
                  <span className="capitalize">
                    {project.acnu_features[0].v1.dosage_route.toLowerCase()}
                  </span>
                ) : (
                  <span>N/A</span>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <span className="capitalize">
                  {project.acnu_features.v1.dosage_route.toLowerCase()}
                </span>
              ) : (
                <span>N/A</span>
              ))}
          </div>
          {/* Dosage Route */}

          <div className="overview-item">
            <h3>Active Ingredients:</h3>
            {project.acnu_features?.v1?.active_ingredients?.length > 0 ? (
              <>
                <ul>
                  {project.acnu_features?.v1.active_ingredients.map(
                    (ingredient, i) => (
                      <li key={i}>{ingredient}</li>
                    )
                  )}
                </ul>
              </>
            ) : (
              "Unavailable"
            )}
          </div>
          {/* Active Ingredients */}

          <div className="overview-item">
            <h3>ACNU Difficulty:</h3>
            <span>{project?.acnu_difficulty || "Unavailable"}</span>
          </div>
          {/* ACNU Difficulty */}
        </div>
      </div>

      <div className="border border-[#0561B7] border-opacity-40 rounded p-4 pb-2">
        <h2 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Brand Summary</h2>
        <img className="rounded md:float-left md:w-[190px] p- md:mr-4 mb-4 md:mb-1" src={project.condition_analysis?.condition_ai_image1 || image } />
        <Summary summary={brand.brand_summary} />
      </div>
    </div>
  );
}

export default MedicationOverview;
