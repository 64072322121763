import { useState, useEffect, useRef } from 'react';
import { $axios } from '../../../services/axiosInstance';
import axios from 'axios';

const useCompanies = () => {
  const initialSearch = sessionStorage.getItem('search') || '';
  const [search, setSearch] = useState(initialSearch);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const ITEMS_PER_PAGE = 100;
  const DEBOUNCE_DELAY = 500;

  // Ref to track the current search value when making a request
  const currentSearchRef = useRef(search);

  // Ref to store the current CancelToken source
  const cancelTokenRef = useRef(null);

  const fetchCompanies = (page, searchTerm) => {
    // Cancel the previous request if it exists
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Cancelling previous request.');
    }

    // Create a new cancel token
    cancelTokenRef.current = axios.CancelToken.source();

    setLoading(true);
    setError(null);

    // Save the current search term at the moment of making the request
    currentSearchRef.current = searchTerm;

    if (searchTerm === '') {
      setCompanies([]);
      setTotalPages(0);
      setLoading(false);
      sessionStorage.setItem('search', "");
      return;
    }

    const baseURL = '/company/project/search/';
    const pageQuery = `?page=${page}`;
    const searchQuery = searchTerm ? `&search=${searchTerm}` : '';

    $axios.get(`${baseURL}${pageQuery}${searchQuery}`, {
      cancelToken: cancelTokenRef.current.token,
    })
      .then(({ data }) => {
        // If the current search term has changed, do not update the companies
        if (currentSearchRef.current !== searchTerm) {
          return;
        }

        setCompanies(data.results);
        setTotalPages(Math.ceil(data.count / ITEMS_PER_PAGE));
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled:', err.message);
        } else {
          // Again, check if the search term has changed before updating state
          if (currentSearchRef.current !== searchTerm) {
            return;
          }

          console.error(err);
          setError(err);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchCompanies(currentPage, search);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(debounceFetch); // Cleanup on unmount or re-run
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted or new search initiated.');
      }
    };
  }, [currentPage]);

  useEffect(() => {
    if (search === '') {
      setCompanies([]);
      setTotalPages(0);
      setLoading(false);
      sessionStorage.setItem('search', "");
      return;
    }

    sessionStorage.setItem('search', search);
    setLoading(true);
    const debounceFetch = setTimeout(() => {
      fetchCompanies(1, search);
    }, DEBOUNCE_DELAY);

    return () => {
      clearTimeout(debounceFetch); // Cleanup on unmount or re-run
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted or new search initiated.');
      }
    };
  }, [search]);

  return { companies, loading, error, currentPage, setCurrentPage, totalPages, search, setSearch };
};

export default useCompanies;
